import React, { Component } from 'react';
import { navigate } from "@reach/router"
import { StaticQuery, graphql } from 'gatsby'

import CartContext from '../Context/CartContext'

export default class ProductToCart extends Component {
  state = {
    product_id: null,
    quantity: 1,
    amount: null,
    message: null,
    errors: null,
  }

  _handleChange = ({ target: { name, value } }) => {
    if (name === 'amount') value = value.replace(',', '.')

    this.setState({ [name]: value })
  }

  _handleSubmit = (e, addToCart) => {
    e.preventDefault()
    this.setState({ errors: false })

    let { product_id, quantity, amount, message } = this.state
    amount = Math.floor(+amount.replace(/\D/g,''))

    let validated = true

    if (!product_id || product_id <= 0) {
      validated = false
    }
    if (!quantity || quantity <= 0) {
      validated = false
    }
    if (!amount || amount < 1) {
      validated = false
    }

    if (validated) {
      const promise = addToCart( product_id, quantity, amount, message )
      promise.then(() => {
        navigate( '/cart/' )
      })
    } else {
      this.setState({ errors: true })
    }
  }


  render() {
    const { errors } = this.state

    return (
      <CartContext.Consumer>
        {({ cartid, cart, get, addItem }) => {
          return (
            <StaticQuery
              query={graphql`
                query products {
                  allNaniniProduct {
                    edges {
                      node {
                        id,
                        product_id,
                        title,
                        type,
                        description,
                        cost
                      }
                    }
                  }
                }
              `}
              render={data => (
                <form className="form-addToCart" onSubmit={e => this._handleSubmit(e, addItem)}>
                  {errors ? (
                  <div className="alert alert-danger">
                    <p className="p-0 m-0">Gelieve alle velden correct in te vullen</p>
                  </div>
                  ) : ''}
                  <div className="form-group">
                    <label htmlFor="inputAmount">Bedrag? (minstens &euro;1.00)</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">&euro;</span>
                      </div>
                      <input type="text" className={`form-control ${errors ? 'is-invalid' : ''}`} id="inputAmount" name="amount" placeholder="Uw bedrag, bv 50" onChange={e => this._handleChange(e)} />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="" htmlFor="inputProductID">Online via e-mail of papieren cadeaubon</label>
                    <select className={`form-control ${errors ? 'is-invalid' : ''}`} id="inputProductID" name="product_id" onChange={e => this._handleChange(e)}>
                      <option>maak een keuze aub</option>
                      {data.allNaniniProduct && data.allNaniniProduct.edges.map(({ node }) => (
                        <option value={node.product_id} key={`product-${node.id}`}>{node.description}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputMessage">Uw boodschap op de cadeaubon zelf?</label>
                    <textarea className={`form-control`} id="inputMessage" name="message" placeholder="Uw boodschap op de cadeaubon zelf" onChange={e => this._handleChange(e)}></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-cart-plus mr-3" aria-hidden="true"></i>
                    <span className="">Toevoegen aan winkelmandje</span>
                  </button>
                </form>
              )}
            />
          )
        }}
      </CartContext.Consumer>
    );
  }
}
