import React from 'react'

import Layout from '../components/layout'
import ProductToCart from '../components/ProductToCart'

export default class Cadeaubon extends React.Component {
  render() {
    return (
      <Layout>
        <h1 className="h1">Geef een cadeaubon van Nanini cadeau!</h1>

        <div className="row">
          <div className="col-md-6">
            <h2 className="h4">Weet je niet goed welk juweeltje je zou kiezen? Of heb je héél snel nog een lieve attentie nodig? Dan is onze cadeaubon ideaal!</h2>
            <p>Je kan deze cadeaubon online bestellen voor een bedrag naar keuze, en vervolgens kan je kiezen voor een digitale bon die je onmiddellijk per mail toegestuurd krijgt. Geef je liever toch een echt cadeautje af? Je kan ook een cadeaubon in Nanini-verpakking bestellen. Dan sturen wij per post een cadeaubon in een leuke giftbox naar je toe, of je kan deze komen ophalen in onze winkel!</p>
          </div>

          <div className="col-md-6">
            <ProductToCart />
          </div>
        </div>
      </Layout>
    )
  }
}
